/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:bbd6602d-8a14-4d58-81d8-45c03ff22ac6",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_OTnlq9kO4",
    "aws_user_pools_web_client_id": "p8s1mvqonvk9k85cblln5q4ee",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://hpqgqp4h5bh2hk3ptknuymc77i.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-h3n2xrqsojd3zl65pdarcubowm",
    "aws_user_files_s3_bucket": "futurefamilymedia205301-dev",
    "aws_user_files_s3_bucket_region": "us-east-2"
};


export default awsmobile;
